.product-properties {

	@include clearfix();

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		height: 70px;
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		font-size: 16px;
		font-family: $ff2;
		font-weight: 400;
		color: $white;
		background: $charcoal-grey-two;
		margin-bottom: 2px;

		width: calc(50% - 20px);
		float: left;

		&:nth-child(even) {
			margin-left: 10px;
		}

		&:nth-child(odd) {
			margin-right: 10px;
		}


		b, strong {
			font-weight: 400;
			opacity: 0.5;
		}
	}

	@include media-breakpoint-down(md) {
		li {
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			min-height: 80px;
			height: auto;

            b , strong {
                margin-top: 5px;
            }
		}
	}

	@include media-breakpoint-down(sm) {
		li {
			width: 100%;
			margin: 0 0 2px 0 $i;
		}
	}

}
