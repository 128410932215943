@font-face {
  font-family: "icon-font";
  src: url('../fonts/icon-font/icon-font.eot');
  src: url('../fonts/icon-font/icon-font.eot?#iefix') format('eot'),
    url('../fonts/icon-font/icon-font.woff2') format('woff2'),
    url('../fonts/icon-font/icon-font.woff') format('woff'),
    url('../fonts/icon-font/icon-font.ttf') format('truetype'),
    url('../fonts/icon-font/icon-font.svg#icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fi {
  display: block;
}

.fi.fi:before {
  display: block;
  font-family: "icon-font";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi.fi-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fi.fi-2x { font-size: 2em; }
.fi.fi-3x { font-size: 3em; }
.fi.fi-4x { font-size: 4em; }
.fi.fi-5x { font-size: 5em; }
.fi.fi-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.fi.fi-angle-down:before { display: block; content: "\EA01" }
.fi.fi-arrow-left:before { display: block; content: "\EA02" }
.fi.fi-arrow-right:before { display: block; content: "\EA03" }
.fi.fi-basket:before { display: block; content: "\EA04" }
.fi.fi-building:before { display: block; content: "\EA05" }
.fi.fi-call-phone-2:before { display: block; content: "\EA06" }
.fi.fi-call-phone:before { display: block; content: "\EA07" }
.fi.fi-close-2:before { display: block; content: "\EA08" }
.fi.fi-close:before { display: block; content: "\EA09" }
.fi.fi-facebook:before { display: block; content: "\EA0A" }
.fi.fi-fax-2:before { display: block; content: "\EA0B" }
.fi.fi-fax:before { display: block; content: "\EA0C" }
.fi.fi-foto:before { display: block; content: "\EA0D" }
.fi.fi-home:before { display: block; content: "\EA0E" }
.fi.fi-instagram:before { display: block; content: "\EA0F" }
.fi.fi-linkedin:before { display: block; content: "\EA10" }
.fi.fi-long-arrow-right:before { display: block; content: "\EA11" }
.fi.fi-mid-arrow-right:before { display: block; content: "\EA12" }
.fi.fi-phone:before { display: block; content: "\EA13" }
.fi.fi-pin-2:before { display: block; content: "\EA14" }
.fi.fi-pin:before { display: block; content: "\EA15" }
.fi.fi-pinterest:before { display: block; content: "\EA16" }
.fi.fi-question:before { display: block; content: "\EA17" }
.fi.fi-twitter:before { display: block; content: "\EA18" }
.fi.fi-video:before { display: block; content: "\EA19" }
.fi.fi-whatsapp:before { display: block; content: "\EA1A" }
.fi.fi-youtube:before { display: block; content: "\EA1B" }