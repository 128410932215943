.home-promo {

	&__item {
		position: relative;
		z-index: 1;
		color: $white;
		height: 100vh;
		display: flex;
		align-items: center;
		padding-top: $header-height;
		width: 100%;
		padding-bottom: 160px + (30 * 2);

		&.is-selected {
			z-index: 2;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;

		&:after {
			display: none;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include gradient-y(rgba($black, 1) 20%, rgba($black, 0) 100%);
		}
	}

	&__title {
		font-family: $ff2;
		font-weight: 400;
		font-size: 48px;
		line-height: 1;

		small {
			font-size: 32px;
			display: block;
			margin-bottom: 5px;
		}
	}

	&__description {
		font-family: $ff2;
		font-weight: 400;
		font-size: 14px;
		margin-top: 20px;
		max-width: 60%;
	}

	&__btn {
		margin-top: 30px;
	}

	.flickity-page-dots {
		max-width: map-get($container-max-widths, xl);
		bottom: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		left: 50%;
		transform: translate(-50%, 0);
		padding: 0 ($grid-gutter-width / 2);
		margin: 0 -5px;

		.dot {
			background: rgba($white, 0.3);
			margin: 0 5px;

			&.is-selected {
				background: $white;
			}
		}
	}

	@include media-breakpoint-down(sm) {

		&__item {
			padding: 40px 0;
		}

		&__description {
			max-width: inherit;
		}

		.flickity-page-dots {
			justify-content: center;
		}
	}

	@include media-breakpoint-only(md) {
		.flickity-page-dots {
			max-width: map-get($container-max-widths, md);
		}
	}

}
