.head-dropdown-menu {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	background: rgba($black, 0.9);
	padding: 10vh 0;
	transform: translate(0, -100%);
	opacity: 0;
	transition: all ease 0.3s;
	display: none;

	&.active {
		display: block;

		&.show {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__head {
		font-family: $ff2;
		font-weight: 400;
		font-size: 32px;
		line-height: 38px;
		color: $white;
		margin-bottom: 40px;
	}

	&__body {
	}

	&__close-btn {
		color: $white;
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		padding: 0 10px;
		border: none;

		i {
			font-size: 28px;
		}
	}

	div[data-menu] {
		display: none;

		&.is-active {
			display: block;
		}
	}

	@include media-breakpoint-down(md) {
		padding: 30px 0;
		position: fixed;
        z-index: 5;
		overflow-y: scroll;
		height: 100%;

		&__head {
			padding-right: 60px;
		}
	}
}
