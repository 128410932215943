.box-group--corporate {
	.box-group__head {
		position: relative;
		z-index: 1;
	}

	.box-group__body {
		position: relative;
		z-index: 0;
	}

	.box-group__description {
		position: relative;
		padding-bottom: 90px;

		small {
			position: absolute;
			bottom: 0;
			left: 0;
			font-size: 24px;
			font-family: $ff2;
			font-weight: 300;
			color: rgba($white, 0.3);

			b {
				font-family: $ff2;
				font-weight: 700;
			}
		}
	}

	.flickity-viewport {
		overflow: hidden;
	}

	.flickity-arrow {
		margin-top: 20px;
		position: relative;
		z-index: 1;
	}

	@include media-breakpoint-down(sm) {
		.box-group__description {
			padding-bottom: 0;

			small {
				position: relative;
				display: block;
				margin-top: 20px;
			}
		}

		.flickity-arrow {
			margin-top: 0;
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
}

.home-corporate {

	&__block {
		position: relative;
	}

	&__color {
		width: 230px;
		height: 100vh;
		max-height: 640px;
		background: $wheat;
	}

	&__image {
		position: absolute;
		top: 50%;
		right: 20px;
	}

}
