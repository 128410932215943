/* Bootstrap */
@import "../../../node_modules/bootstrap/scss/bootstrap";

/* Borubon */
@import "../../../node_modules/bourbon/core/bourbon";

/* Hamburger Menu */
@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

/* Slick Carousel */
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";

/* Animate CSS */
@import "../../../node_modules/animatewithsass/animate.scss";

/* Aos */
@import "../../../node_modules/aos/src/sass/aos.scss";

/* Font Awesome */
// @import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
// @import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
// @import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
// @import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
