.page-breadcrumb {
	position: relative;
	z-index: 1;
	margin-top: 10px;

	&__link {
		font-size: 12px;
		font-weight: 500;
		color: $white;
		opacity: 0.5;

		&:after {
			content: '/';
			display: inline-block;
			font-size: 85%;
			margin-left: 8px;
			margin-right: 8px - 4;
		}

		&:hover {
			opacity: 1;
			color: $white;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}
