.project-information {
	background: $charcoal-grey-two;
	padding: 50px;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0 (($grid-gutter-width / 2) * -1);
		display: flex;


		li {
			display: flex;
			flex-direction: column;
			padding: 0 ($grid-gutter-width / 2);
			font-size: 16px;
			font-family: $ff2;
			font-weight: 400;

			b, strong {
				font-size: 11px;
				font-weight: 500;
				text-transform: uppercase;
				opacity: 0.5;
				margin-bottom: 5px;
			}
		}
	}

	@include media-breakpoint-down(md) {
		padding: 30px;

		&__item {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			padding-bottom: 15px;
			border-bottom: 1px solid rgba($white, 0.1);
		}

		&__value {
			margin-top: 0;
			margin-left: 10px;
		}
	}
}