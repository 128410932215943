.flickity-viewport {
	overflow: visible;
}

.flickity-arrow {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	&__btn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 15px 0;
		width: 20px;
		border: none;
		color: $white;
		opacity: 0.5;
		transition: all ease 0.3s;
		overflow: hidden;

		&:hover {
			color: $wheat;
			opacity: 1;
			width: 44px;
		}

		&--prev {
		}

		&--next {
			margin-left: 15px;
		}
	}

	&__btn-icon {
		font-size: 8px;
	}
}
