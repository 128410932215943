.page-accordion {

	&__item {
		margin-bottom: 10px;
		background: $charcoal-grey-two;
	}

	&__head {
	}

	&__body {
		&-inner {
			padding: 30px 40px;
			background: darken($charcoal-grey-two, 2%);
			@include article();
		}
	}

	&__btn {
		width: 100%;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 80px;
		padding: 0 40px;
		font-size: 18px;
		font-weight: 500;
		color: $white-five;

		i {
			opacity: 0.5;
			font-size: 14px;
		}

		&:hover {
			color: $wheat;
			background: darken($charcoal-grey-two, 2%);
		}

		&[aria-expanded="true"] {
			background: darken($charcoal-grey-two, 2%);

			i {
				transform: rotate(180deg);
			}
		}
	}

}