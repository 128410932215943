.head-dropdown-products {

	&__item {
		padding-right: 30px;
	}

	&__head {
		font-size: 15px;
		font-weight: 600;
		text-transform: uppercase;
		color: rgba($white, 0.5);
		border-bottom: 1px solid rgba($white, 0.1);
		padding: 12px 0;
		margin-bottom: 12px;
	}

	&__body {
		display: flex;
		flex-direction: column;
	}

	&__link {
		font-size: 11px;
		font-weight: 500;
		text-transform: uppercase;
		color: rgba($white, 0.5);
		padding: 10px 0;
	}

}
