.box-group--activity {
    position: relative;

    .box-group__head {
        margin-bottom: -0px;
        padding: 60px 0;
    }

    .box-group__title {
        opacity: 0.5;
    }

    .flickity-arrow {
        position: absolute;
        z-index: 2;
        transform: rotate(90deg);
        transform-origin: bottom right;
        right: -20px;
        top: 50%;
        margin-top: -20px;
    }

    @include media-breakpoint-down(sm) {
        &:before {
            display: none;
        }

        .box-group__head {
            padding: 30px;
        }

        .box-group__title {
            opacity: 1;
            text-align: center;
            position: relative;
            z-index: 2;
        }

        .flickity-arrow {
            position: relative;
            right: 0;
            transform: none;
            margin: 20px 0;
        }
    }

    @include media-breakpoint-only(md) {
    }
}

.home-activity {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        width: calc(50%);
        height: 100%;
        left: 0;
        top: 0;
        background: $charcoal-grey-two;
    }

    &__list {
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    &__item {
        padding: 80px 0;
    }

    &__title {
        font-family: $ff2;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.4;
        @include shortText(24px, 1.4, 2);
    }

    &__description {
        font-family: $ff2;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        margin-top: 15px;
        @include shortText(14px, 1.5, 3);
    }

    &__order {
        font-family: $ff;
        font-weight: 700;
        font-size: 260px;
        position: absolute;
        z-index: 0;
        left: -30px;
        bottom: -30px;
        line-height: 0.75;
        color: $charcoal-grey-two;
    }

    @include media-breakpoint-down(sm) {
        &:before {
            display: none;
        }

        &__item {
            padding: 40px;
            background: $charcoal-grey-two;
        }

        &__order {
            width: 100%;
            left: 0;
            bottom: -100px;
            text-align: center;
            font-size: 140px;
        }
    }
}

.home-activity-image {
    position: relative;
    height: 420px;
    overflow: hidden;
    border: 10px solid $charcoal-grey-two;

    &__src {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all ease 0.3s;
        width: 100%;

        &.is-selected {
            opacity: 1;
            z-index: 2;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0 30px;
        height: 200px;

        &__src {
            max-width: 100%;
            @include centerImage(100%, 100%);
        }
    }

    @include media-breakpoint-only(md) {
        &__src {
            width: 48%;
            left: 0;
            transform: none;
            margin: -50px 0 0 0;
        }
    }
}

.home-activity-tab {
    margin-top: 30px;

    &__inner {
        display: flex;
        justify-content: center;
    }

    &__title {
        position: relative;
        font-family: $ff2;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        color: $white;
        opacity: 1;
        transition: all ease 0.3s;
        text-align: center;
        padding: 25px 0;
        margin: 0 20px;
        border-top: 2px solid transparent;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.is-selected {
            opacity: 1;
            border-top-color: $wheat;
            color: $wheat;

            &:before {
                content: '';
                position: absolute;
                top: -10px;
                left: 50%;
                transform: translate(-50%, 0);
                @include triangle(up, 8px, 8px, $wheat);
            }
        }
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}
