.box-group--services {

	@include media-breakpoint-only(md) {
		display: flex;
		align-items: center;
		justify-content: center;

		.box-group__head {
			margin: 0;
			margin-right: 30px;
			width: 50%;
		}

		.box-group__body {
			width: 50%;
		}
	}

}

.home-services {
	@include ulLiStyle();
}
