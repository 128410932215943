.box-group--projects {
	overflow: hidden;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		width: calc(50% - 10px);
		height: 100%;
		background: $body-bg;
	}

	@include media-breakpoint-down(sm) {
		&:before {
			display: none;
		}
	}
}

.home-projects {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 508px;
	background: $charcoal-grey-two;
	color: $white-five;

	&__head {
		font-family: $ff2;
		font-weight: 300;
		font-size: 32px;
		line-height: 1;
	}

	&__body {
		margin-top: 40px;
		overflow: hidden;
	}


	&__item {
		display: flex;
		flex-direction: column;
		min-height: 220px;
		margin-right: 20px;
	}

	&__title {
		font-family: $ff2;
		font-weight: 400;
		font-size: 16px;
	}

	&__description {
		font-family: $ff2;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.5;
		margin-top: 10px;
		opacity: 0.5;
		@include shortText(16px, 1.5, 4);
	}

	&__order {
		font-size: 24px;
		font-family: $ff;
		font-weight: 300;
		color: $white-five;
		opacity: 0.3;

		b {
			font-weight: 700;
		}
	}

	.flickity-arrow {
		position: absolute;
		bottom: 30px;
		left: 75px;
	}

	@include media-breakpoint-down(sm) {
		min-height: inherit;

		&__head {
			margin-top: 0;
		}

		.flickity-arrow {
			left: 40px;
			bottom: 30px;
		}
	}

	@include media-breakpoint-down(md) {
		min-height: inherit;
		height: 100%;

		.flickity-arrow {
			left: 16px * (0.25 * 14);
			bottom: 30px;
		}
	}


}

.home-projects-gallery {
	position: relative;
	z-index: 0;

	&__item {
		opacity: 0.2;
		transition: all ease 0.3s;
		width: 100%;

		&.is-selected {
			opacity: 1;
		}
	}

	&__image {
		width: 100%;
	}

	@include media-breakpoint-down(sm) {
		margin-right: 0;

		&__item {
			width: 100%;
		}

		&__image {
			@include centerImage(100%, 220px);
		}
	}

	@include media-breakpoint-down(md) {
		margin-right: -20px;

		&__item {
			width: 100%;
		}

		&__image {
			width: 100%;
		}
	}
}
