@mixin labelStyle() {
	position: absolute;
	top: 27px;
	left: 0;
	z-index: 1;
	color: fade_out($input-color, 0.6);
	line-height: 20px;
	margin-top: -10px;
	pointer-events: none;
	transition: all ease 0.3s;
	font-family: $ff2;
	font-weight: 400;
	font-size: $input-btn-font-size;
}

@mixin labelStackedStyle() {
	top: 0;
	font-size: 12px;
	color: fade_out($input-color, 0.6);
}

.form-group-stacked {
	position: relative;

	.form-label {
		@include labelStyle();
	}

	.form-control {
		&:focus {
			& + label {
				@include labelStackedStyle();
			}
		}
	}

	&.is-stacked {
		label {
			@include labelStackedStyle();
		}
	}
}
