.showroom-info {

	color: $white-five;

	&__block {
		position: relative;

		&--image {
			margin-top: 170px;
		}

		&--text {
			z-index: 1;
			background: $charcoal-grey-two;
			padding: 80px;
			min-height: 510px;
			margin: 330px (-20px - 30) 0;
		}

		&--map {
			z-index: 0;
			background: $charcoal-grey-two-2;
			min-height: 680px;
			margin-left: -200px;
		}
	}

	&__image {
		width: 100%;
	}

	&__title {
		font-size: 32px;
		font-family: $ff2;
		font-weight: 300;
		margin-bottom: 40px;
	}

	&__email {
		font-size: 16px;
		font-family: $ff2;
		font-weight: 400;
		color: $white-five;
		display: inline-block;
		margin-bottom: 10px;
	}

	&__address {
		font-size: 16px;
		font-family: $ff2;
		font-weight: 400;
		opacity: 0.4;
		margin-bottom: 20px;
	}

	&__more-btn {
	}

	&__more-btn-icon {
		font-size: 8px;
	}

	&__map {
		width: 100%;
		height: 100%;
	}

	@include media-breakpoint-down(md) {
		&__block {
			&--text {
				margin: 0;
				min-height: inherit;
				padding: 100px 60px;
			}

			&--map {
				min-height: inherit;
				margin-left: 0;
				height: 100%;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		&__block {
			&--text {
				padding: 60px;
			}

			&--map {
				height: 280px;
			}
		}
	}

}
