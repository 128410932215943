body {

	overflow-x: hidden;

	&.homepage-body {
		.site-head {
			background: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}

	@include media-breakpoint-down(md) {
		&.has-mobile-menu {
			overflow: hidden;
		}
	}
}

section {
	&.project-detail-content, &.product-detail-content, &.blog-detail-content {
		overflow-x: hidden;
	}
}

.page-content {
}

.container {
	@include media-breakpoint-down(sm) {
		padding: 0 $grid-gutter-width;
	}
}
