.box-group--production-system {
	position: relative;

	.box-group__btn {
		min-width: inherit;
		margin-top: 0;
		padding: 0;
		transition: all ease 0.3s;

		&:hover {
			padding: 5px;
			margin: -5px;
		}
	}

	.flickity-list {
		overflow: hidden;
	}

	&:after, &:before {
		content: '';
		position: absolute;
		right: 0;
		width: 50%;
		height: 20px;
		background: $charcoal-grey-two;
	}

	&:before {
		top: 0;
	}

	&:after {
		bottom: 0;
	}

	@include media-breakpoint-down(md) {
		&:after, &:before {
			display: none;
		}

		.flickity-arrow {
			bottom: auto;
			top: -30px;
			right: -10px;
		}
	}

	@include media-breakpoint-only(md) {
		.flickity-arrow {
			top: -5px;
			right: 10px;
		}
	}
}

.home-production-system {
	position: relative;
	border: 20px solid $charcoal-grey-two;
	border-right: 0;

	&__item {
		display: flex;
		align-items: center;
	}

	&__image {
		margin-right: 30px;
	}

	&__title {
		font-size: 16px;
		line-height: 1;
		font-family: $ff2;
		font-weight: 400;
		color: $white;
	}

	&__description {
		font-size: 14px;
		line-height: 1.6;
		font-family: $ff2;
		font-weight: 400;
		margin-top: 15px;
	}

	@include media-breakpoint-down(md) {
		border: 10px solid $charcoal-grey-two;

		&__item {
			display: block;
		}

		&__image {
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	@include media-breakpoint-only(md) {
		&__item {
			display: flex;
		}

		&__image {
			margin-right: 20px;
			margin-bottom: 0;
		}
	}
}
