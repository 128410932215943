.project-gallery {

	position: relative;

	&__item {
		width: 100%;
	}

	&__image {
		display: block;
		@include centerImage(100%, 590px);
	}

	&__title {
		font-family: $ff2;
		font-weight: 300;
		font-size: 24px;
		color: fade_out($white, 0.5);
		background: rgba($charcoal-grey-two, 0.8);
		padding: 35px;
		position: absolute;
		bottom: 5px;
		left: 5px;
		max-width: 35%;
	}

	.flickity-viewport {
		overflow: hidden;
	}

	.flickity-arrow {
		&__btn {
			position: absolute;
			top: 50%;
			margin-top: (38px / 2) * -1;

			&--prev {
				left: -50px - 15;
			}

			&--next {
				right: -50px - 15;
			}
		}

		@include media-breakpoint-down(lg) {
			&__btn {
				z-index: 1;
				top: 100%;
				margin-top: -38px - 20;

				&--prev {
					left: auto;
					right: 38px + 20 + 20;
				}

				&--next {
					right: 20px;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		&__item {
		}

		&__image {
			@include centerImage(100%, auto);
		}

		&__title {
			top: 0;
			max-width: 50%;
			transform: none;
			font-size: 18px;
			padding: 20px;
		}
	}

	@include media-breakpoint-down(sm) {
		&__title {
			position: relative;
			top: 0;
			transform: none;
			max-width: inherit;
		}
	}

}
