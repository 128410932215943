@font-face {
	font-family: 'Tisa Bold';
	src: url('#{$font}tisa/subset-TisaWebW03-Bold.woff2') format('woff2'),
	url('#{$font}tisa/subset-TisaWebW03-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Tisa Regular';
	src: url('#{$font}tisa/subset-TisaWebW03-Regular.woff2') format('woff2'),
	url('#{$font}tisa/subset-TisaWebW03-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Tisa Light';
	src: url('#{$font}tisa/subset-TisaWebW03-Light.woff2') format('woff2'),
	url('#{$font}tisa/subset-TisaWebW03-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Tisa Medium';
	src: url('#{$font}tisa/subset-TisaWebW03-Medium.woff2') format('woff2'),
	url('#{$font}tisa/subset-TisaWebW03-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

