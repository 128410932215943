$root: product-list;

.#{$root} {
	position: relative;
	overflow: hidden;
	margin-bottom: $grid-gutter-width * -1;

	div[class*="col"] {
		position: inherit;
	}

	&__item {
		position: relative;
		background: $charcoal-grey-two;
		color: $white-five;
		margin-bottom: $grid-gutter-width;

		&:hover {
			.#{$root}__image {
				opacity: 1;
			}

			.#{$root}__more-btn {
				color: $white;
			}

			.#{$root}__title {
				color: $white;
			}
		}

		&--active {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			display: flex;
			transition: all ease 0.3s;

			.#{$root}__image-box {
				height: 446px;
				min-width: 340px;
			}

			.#{$root}__show-active {
				display: block;
			}

			.#{$root}__show-active-flex {
				display: flex;
			}

			.#{$root}__hide-active {
				display: none;
			}


		}

		&.has-video {
			.#{$root}__image-box {
				position: relative;

				&:before {
					font-family: "icon-font";
					font-style: normal;
					font-weight: normal;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					content: "";
					position: absolute;
					z-index: 1;
					top: 50%;
					left: 50%;
					color: #fff;
					border: 2px solid #fff;
					font-size: 20px;
					width: 70px;
					height: 70px;
					border-radius: 70px;
					margin-left: -35px;
					margin-top: -35px;
					display: flex;
					align-items: center;
					justify-content: center;
					pointer-events: none;
					transition: all ease 0.3s;
					transform-origin: center;
				}
			}

			&:hover {
				.#{$root}__image-box {
					&:before {
						background: #fff;
						color: $white-five;
					}
				}
			}
		}
	}

	&__image-box {
		height: 245px;
		overflow: hidden;
	}

	&__text-box {
		padding: ($grid-gutter-width * 1.5);
	}


	&__image {
		opacity: 0.8;
		transition: all ease 0.3s;
		width: 100%;
		@include centerImage(100%, 100%);
	}

	&__size {
		font-family: $ff;
		font-weight: 600;
		font-size: 24px;
	}

	&__location {
		font-size: 14px;
		font-weight: 500;
	}

	&__title {
		font-family: $ff2;
font-weight: 300;
		font-size: 24px;
		transition: all ease 0.3s;
		margin-top: 10px;
		@include shortText(24px, $line-height-base, 2);
	}

	&__more-btn {
		display: inline-block;
		margin-top: 20px;
	}

	&__more-btn-icon {
		font-size: 8px;
		transition: all ease 0.3s;
	}

	&__properties {

	}

	&__properties-item {
		display: flex;
		align-items: center;
		margin: 10px 0;
		line-height: 26px;
	}

	&__properties-label {
		font-size: 16px;
		font-family: $ff2;
font-weight: 400;
		margin-right: 20px;

		img {
			max-width: 32px;
		}
	}

	&__properties-value {
		font-size: 15px;
		font-family: $ff;
		font-weight: 500;
	}

	&__block {
		&--left {
			width: 40%;
		}

		&--right {
			img {
				max-width: 200px;
			}
		}
	}

	&__clone {
		.#{$root}__item {
			transition: transform ease 0.3s;
			transform: translate(0, -50px);

			&--active {
				transform: translate(0, 0);
			}
		}
	}

	&__show-active {
		display: none;
	}

	&__show-active-flex {
		display: none;
	}

	&__hide-active {
		display: block;
	}


}
