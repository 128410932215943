.box-group--catalog {

	@include media-breakpoint-only(md) {
		display: flex;
		align-items: center;

		.box-group__head {
			margin: 0;
			margin-right: 30px;
			width: 50%;
		}

		.box-group__body {
			width: 50%;

			img {
				width: 100%;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.box-group__body {
			img {
				width: 100%;
			}
		}
	}

}
