$maps-height : 340px;

.contact-map {
	height: $maps-height;
	overflow: hidden;

	> div {
		height: $maps-height + 110;
		background: $charcoal-grey-two;
	}
}