.site-head {
	position: relative;
	z-index: 10;
	padding: 40px 0;
	height: $header-height;
	background: $charcoal-grey-two;

	&__block {
		transition: all ease 0.3s;

		&--left {
		}

		&--right {
		}
	}

	@include media-breakpoint-down(md) {
	}

	@include media-breakpoint-down(md) {
        padding: 0;
		background: none;
		height: $header-height-sm;

		&__block {
			&--left {
				position: fixed;
                z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				background: $charcoal-grey-two;
				padding: 20px $grid-gutter-width;
				box-shadow: 0 8px 24px 0 rgba($black, 0.2);
                height: $header-height-sm;
			}

            &--right {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow-x: hidden;
                overflow-y: scroll;
                background: rgba($black, 0.9);
                padding: 10vh 0;
                transform: translate(-100%, 0);
                opacity: 0;
                transition: all ease 0.3s;
                display: none;

                &.is-active {
                    display: block;
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }


			&--no-bg {
				background: none;
				box-shadow: none;
			}
		}
	}
}

.head-logo {
	&__link {
	}

	&__image {
	}
}

.head-menu {
	display: flex;
	align-items: center;

	&__item-link {
		font-size: 14px;
		font-weight: 500;
		color: $white-five;
		margin: 0 15px;
		padding: 10px 0;
		text-transform: uppercase;
		border-bottom: 2px solid transparent;
		cursor: pointer;

		&--active, &:hover, .show & {
			color: $wheat;
			border-bottom-color: $wheat;
		}
	}

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;
        padding: 50px 0;

		&__item-link {
			margin: 0;
			font-size: 16px;
			padding: 15px 0;
            display: block;
		}
	}
}

.head-contact {
	text-align: right;

	&__label {
		font-size: 12px;
		font-weight: 500;
		opacity: 0.5;
	}

	&__value {
		font-size: 16px;
		font-weight: 600;
		color: $white;
		margin-top: 5px;

		&:hover {
			color: $wheat;
		}
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: 15px;
        text-align: center;
	}
}

.head-hamburger {

	padding: 10px;
	margin: -10px;

	&__btn {
		transition: all ease 0.3s;

		&:hover, {
			color: $wheat;

			.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
				background: $wheat;
			}
		}
	}

	&__btn-text {
		font-size: 11px;
		text-transform: uppercase;
		margin-left: 10px;
	}
}
