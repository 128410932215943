.process-divider {
	position: relative;
	margin: 100px 0;
	padding: 60px 100px;
	background: $charcoal-grey-two;
	color: $white;

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 50%;
		width: 100%;
		height: 100%;
		background: $charcoal-grey-two;
	}

	&__text {
		font-family: $ff2;
font-weight: 300;
		font-size: 48px;
		line-height: 1;
	}

	@include media-breakpoint-down(md) {
		&:before {
			display: none;
		}

		&__text {
			text-align: center;
		}
	}

	@include media-breakpoint-down(sm) {
		margin: 30px 0;
		padding: 30px;

		&__text {
			font-size: 32px;

		}
	}
}
