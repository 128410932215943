$root: category-list;

.#{$root} {
	margin-bottom: $grid-gutter-width * 1.50;
	background: none;

	&__item {
		position: relative;
		min-height: 160px;
		min-width: 160px;
		width: 160px;
		text-align: center;
		color: rgba($white, 0.7);
		border: 1px solid rgba($white, 0.1);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		transition: all ease 0.3s;
		padding: 10px;

		&:hover, &--active {
			border-color: $wheat;

			.#{$root}__icon {
				color: $wheat;
			}

			.#{$root}__description {
				margin-top: 15px;
				opacity: 1;
				height: 64px;
			}

			.#{$root}__more-arrow {
				color: $wheat;
			}
		}

		&.has-bg {
			&:hover {
				.#{$root}__title {
					color: $white-five;
				}
			}

			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 50%;
				@include gradient-y(transparent, #fff);
			}
		}
	}

	&__icon-box {
		margin-bottom: 20px;
		position: relative;
		z-index: 2;
	}

	&__icon {
		font-size: 42px;
		width: 42px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: all ease .3s;
		color: rgba($white, 0.2);
	}

	&__image {
		transition: $transition-base;
	}

	&__title {
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		z-index: 2;
	}

	&__description {
		font-size: 16px;
		font-family: $ff2;
		line-height: 1.4;
		color: $white-five;
		margin-top: 0;
		@include shortText(16px, 1.4, 3);
		height: 0;
		opacity: 0;
		transition: all ease 0.3s;
		z-index: 2;
	}

	&__more-arrow {
		font-size: 8px;
		color: rgba($white, 0.5);
	}

	&__bg {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.5;
	}

	.flickity-button {
		color: $white-five;
		background: none;
		outline: none;
		box-shadow: none;
		width: 32px;
		height: 32px;

		&.next {
			right: -32px;
		}

		&.previous {
			left: -32px;
		}
	}

	@include media-breakpoint-down(sm) {
		overflow-x: hidden;

		&__item {
			min-width: inherit;
            width: 100%;
		}
	}
}

.#{$root}--activity {
	margin-bottom: $grid-gutter-width * -1;

	.#{$root}__item {
		min-height: 340px;
		min-width: inherit;
		margin-bottom: $grid-gutter-width;
		align-items: flex-start;
		justify-content: flex-end;
		padding: 30px;
		margin-right: 0;
		width: auto;
		text-align: left;
	}

	.#{$root}__title {
		max-width: 60%;
	}

	@include media-breakpoint-down(sm) {
		overflow: visible;

		.#{$root}__item {
			width: 100%;
			margin: 0;
			margin-bottom: 20px;
		}
	}
}

.#{$root}--home {
    display: none;
	position: absolute;
	bottom: 30px + 10 + (30 * 2);
	left: 0;
	width: 100%;
	margin-bottom: 0;

	.#{$root}__item {
		color: $white-five;
		border: 1px solid rgba($white-five, 0.5);
		background: none;
		// background: rgba($body-bg, 0.11);
		// backdrop-filter: blur(10px);

		&:hover, &--active {
			border-color: $wheat;

			.#{$root}__icon {
				&--front {
					opacity: 0;
				}

				&--back {
					opacity: 1;
				}
			}
		}
	}

	.#{$root}__icon {
		color: $white-five;

		&--front {
			opacity: 0.2;
		}
	}

}
