/* ===== Helper */
$img: '../images/';
$font: '../fonts/';
$i: !important;


/* ===== Fonts */
$ff: 'Roboto';
$ff2: 'Roboto Slab';

/* ===== Colors */
// $wheat: #fac894;
// $blush: #ecba8a;
// $charcoal-grey: #2f3339;
// $charcoal-grey-two: #373b41;
// $charcoal-grey-three: #292d32;
// $white-two: #d8d8d8;
// $white-five: #d3d3d3;
// $slate-grey: #5c5f65;
// $white-five: #9a9b9e;
// $gunmetal: #4a4f56;
// $battleship-grey: #73767a;
// $greyish: #b2b2b2;
// $brownish: #a47063;

$wheat: #feb800;
$charcoal-grey: #fff;
$charcoal-grey-two: #f9f9f9;
$charcoal-grey-two-2: #e8e8e8;
$white-five: #2f3339;
$brownish: #a47063;

/* ===== Font Awesome */
$fa-font-path: "../fonts/font-awesome" !default;
$fa-font-size-base: 16px !default;
$fa-font-display: auto !default;
$fa-css-prefix: fa !default;
$fa-version: "5.11.2" !default;
$fa-border-color: #eee !default;
$fa-inverse: #fff !default;
$fa-li-width: 2em !default;
$fa-fw-width: (20em / 16);
$fa-primary-opacity: 1 !default;
$fa-secondary-opacity: .4 !default;

/* ===== Hamburger Menu */
$hamburger-padding-x: 0 !default;
$hamburger-padding-y: 0 !default;
$hamburger-layer-width: 21px !default;
$hamburger-layer-height: 1px !default;
$hamburger-layer-spacing: 5px !default;
$hamburger-layer-color: #000 !default;
$hamburger-layer-border-radius: 10px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

/* ===== Hedaer */

$header-height-sm: 62px;
$header-height: 123px;
