.page-head {
    position: relative;
    background: $charcoal-grey-two;
    overflow: hidden;
    padding: 40px 0 (40px * 2);

    &__title {
        position: relative;
        font-family: $ff2;
        font-weight: 300;
        font-size: 48px;
        line-height: 1;
        margin-bottom: 0;

        &:before {
            content: attr(data-title-shadow);
            position: absolute;
            z-index: 0;
            top: 80px;
            left: -10px;
            font-size: 120px;
            line-height: 1;
            opacity: 0.05;
            white-space: nowrap;
        }

        &--noshadow {
            &:before {
                display: none;
            }
        }
    }

    &__sub-title {
    }

    &__description {
        font-size: 14px;
        line-height: 1.7;
        font-family: $ff2;
        font-weight: 400;
        color: $white-five;

        &--scroll {
            height: 105px;
            padding-right: 15px;
            margin-bottom: -35px;

            &:after {
                content: '';
                position: absolute;
                bottom: -35px;
                left: 0;
                width: 100%;
                height: (105px / 1.3);
                @include gradient-y(transparent, $charcoal-grey-two, 0%, 80%);
                transition: all ease 0.3s;
            }

            &:hover {
                &:after {
                    opacity: 0;
                }
            }
        }
    }

    &__image {
    }

    &__btn {
    }

    @include media-breakpoint-down(sm) {
        padding: ($grid-gutter-width * 2) 0;

        &__title {
            font-size: 32px;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 40px 0;

        &__title {
            &:before {
                display: none;
            }
        }

        &__description {
            &--scroll {
                margin-bottom: 0;

                &:after {
                    bottom: 0;
                }
            }
        }
    }
}
