.home-corporate-multimedia {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($black, 0.47);
	}

	&__image {
		position: relative;
		z-index: 0;
	}

	&__text-box {
		position: absolute;
		z-index: 2;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 30px;
	}

	&__title {
		font-family: $ff2;
font-weight: 300;
		font-size: 32px;
		line-height: 1;
		color: $white;
		max-width: 70%;
	}

	&__btn {
		max-width: 160px;
		margin-top: 20px;
	}

	@include media-breakpoint-down(sm) {
		&__image {
			width: 100%;
		}
	}
}
