.site-foot {
	background: $charcoal-grey-two;

	&__block {
		padding: $grid-gutter-width * 1.5 0;
	}

	@include media-breakpoint-down(sm) {
		&__block {
			padding: $grid-gutter-width 0;
		}
	}
}

.foot-logo {
	&__link {
	}

	&__image {
	}
}

.foot-address {
	&__title {
	}

	&__description {
	}

	&__link {
		&:hover {
		}
	}
}

.foot-contact {
	&__icon {
	}

	&__label {
	}

	&__value {
		&:hover {
		}
	}
}

.foot-social {
	display: flex;
	margin: 0 -5px;

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 5px;
		width: 40px;
		height: 40px;
		border-radius: 40px;
		border: 1px solid fade-out($white, 0.8);
		color: $white-five;
		transition: all ease 0.3s;

		&:hover {
			background: $wheat;
			border-color: $wheat;
			color: $charcoal-grey;
			transform: rotate(360deg);
		}
	}

	&__icon {
		font-size: 16px;
	}
}

.foot-copyright {
	&__text {
		font-size: 12px;
		font-weight: 500;
		color: $white-five;
		opacity: 0.5;
	}
}

.foot-link {
	margin-bottom: $grid-gutter-width * 2;

	&__item {
	}

	&__item-link {
		display: block;
		font-size: 20px;
		font-family: $ff2;
		font-weight: 500;
		margin-bottom: 30px;
		color: $white-five;
	}

	&__children {
		display: flex;
		flex-direction: column;
	}

	&__children-link {
		font-size: 14px;
		font-weight: 400;
		padding: 5px 0;
		color: $white-five;
		transition: all ease 0.3s;

		&:hover {
			color: $wheat;
			text-decoration: underline;
		}
	}

	&__text {
		font-size: 14px;
		font-weight: 400;
		padding: 5px 0;
		color: $white-five;
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: $grid-gutter-width;
	}
}

.foot-form {
	&__title {
		font-size: 24px;
		font-family: $ff2;
		font-weight: 400;
		color: $white-five;
		margin-right: $grid-gutter-width;
	}

	&__body {
		width: 100%;
	}

	@include media-breakpoint-down(lg) {
		display: block;

		&__title {
			margin-right: 0;
			margin-bottom: $grid-gutter-width;
			padding: 0;
		}
	}
}
