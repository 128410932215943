.btn-circle {
	border-radius: ($input-padding-y * 2 + $input-btn-font-size);
}

.btn-animate-arrow {
	i {
		@include shake(
			$duration: 3s,
			$count: infinite,
			$delay: 0.3s,
			$function: ease,
			$fill: both
		);
	}
}