/* ===== Theme Variable */
@import "theme/variable";
@import "theme/mixin";
@import "bootstrap-custom/bootstrap-var";

/* ===== Node Modules */
@import "vendor";

/* ===== Bootstrap */
@import "bootstrap-custom/bootstrap";

/* ===== Other Plugin */
@import "plugins/fancybox";
@import "plugins/flickity";

/* ===== Partials */
@import "partials/header";
@import "partials/footer";

/* ===== Global */
@import "theme/helper";
@import "theme/font-face";
@import "theme/icon-font";
@import "theme/theme";

/* ===== Components */

/* Common */
@import "../components/page-head/page-head";
@import "../components/page-breadcrumb/page-breadcrumb";
@import "../components/page-content/page-content";
@import "../components/page-article/page-article";
@import "../components/page-sidebar/page-sidebar";
@import "../components/page-tab/page-tab";
@import "../components/page-gallery/page-gallery";
@import "../components/box-group/box-group";

/* Homepage */
@import "../components/home-services/home-services";

/* Product */
@import "../components/category-list/category-list";
@import "../components/product-list/product-list";
@import "../components/product-gallery/product-gallery";
@import "../components/product-properties/product-properties";

/* Project */
@import "../components/project-list/project-list";
@import "../components/project-gallery/project-gallery";
@import "../components/project-information/project-information";

/* Contact */
@import "../components/contact-address/contact-address";
@import "../components/contact-form/contact-form";
@import "../components/contact-map/contact-map";

/* Other */
@import "../components/process-list/process-list";
@import "../components/process-divider/process-divider";
@import "../components/process-jump-menu/process-jump-menu";
@import "../components/showroom-info/showroom-info";

/* Homepage */
@import "../components/home-promo/home-promo";
@import "../components/home-category-list/home-category-list";
@import "../components/home-corporate/home-corporate";
@import "../components/home-multimedia/home-multimedia";
@import "../components/home-prefab-about/home-prefab-about";
@import "../components/home-make-process/home-make-process";
@import "../components/home-services/home-services";
@import "../components/home-catalog/home-catalog";
@import "../components/home-production-system/home-production-system";
@import "../components/home-projects/home-projects";
@import "../components/home-activity/home-activity";
@import "../components/head-dropdown-menu/head-dropdown-menu";
@import "../components/head-dropdown-products/head-dropdown-products";
@import "resources/assets/components/page-accordion/page-accordion.scss";

/* Plugins */
@import "../components/flickity/flickity";
