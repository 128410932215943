.page-tab {
    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
    }

    &__item {
    }

    &__link {
        display: block;
        font-size: 16px;
        line-height: 36px;
        font-family: $ff2;
        font-weight: 500;
        padding: 10px 0;
        margin: 0 20px;
        border-bottom: 2px solid transparent;
        color: $white;
        opacity: 0.4;
        transition: $transition-base;

        &:hover {
            color: $white;
            opacity: 1;
        }

        &.active {
            border-bottom-color: $wheat;
            opacity: 1;
        }
    }


    &__content {
        margin-top: $grid-gutter-width * 2;
    }

    &__pane {
    }

    @include media-breakpoint-down(sm) {
        &__item {
            width: 33%;
        }

        &__link {
            line-height: 1.4;
            margin: 0;
        }
    }
}
