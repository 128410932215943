html {
	scroll-behavior: smooth;
}

.process-list-body {
	position: relative;
	overflow-y: auto;
	height: 100%;
	scroll-behavior: smooth;
}

.process-list {

	&__item {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: row-reverse;
		color: $white-five;
		padding-top: 40px;

		& + & {
			margin-top: 50px;
		}
	}

	&__title {
		font-family: $ff2;
		font-weight: 300;
		font-size: 32px;
		line-height: 36px;
		margin-bottom: 30px;
	}

	&__description {
		font-family: $ff2;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;

		@include ulLiStyle();
	}

	&__image {
		margin-left: $grid-gutter-width * 2;
	}

	&__btn {
		margin-top: 20px;
	}

	&__help-text {
		font-size: 11px;
		font-weight: 600;
		color: $wheat;
		margin-top: 30px;
	}

	@include media-breakpoint-only(lg) {
		&__image {
			@include centerImage(260px, auto);
		}
	}

	@include media-breakpoint-only(md) {
		&__image {
			@include centerImage(220px, auto);
		}
	}

	@include media-breakpoint-down(sm) {

		&__item {
			display: block;
		}

		&__image {
			@include centerImage(220px, auto);
			margin-left: 0;
			margin-bottom: 20px;
		}
	}

}