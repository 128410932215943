.home-make-process {
	padding-bottom: 15px;

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	&__order {
		font-family: $ff;
		font-size: 24px;
		font-weight: 700;
		color: rgba($white, 0.2);
		margin-bottom: 5px;
	}

	&__title {
		font-family: $ff2;
		font-weight: 400;
		font-size: 14px;
		color: $white;
	}

	@include media-breakpoint-down(sm) {
		white-space: nowrap;
		overflow-x: scroll;

		&__item {
			margin-right: 30px;
		}
	}
}
