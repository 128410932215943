.process-jump-menu {

	display: flex;
	flex-direction: column;

	&__link {
		font-size: 11px;
		line-height: 30px;
		font-weight: 600;
		color: $white;
		opacity: 0.5;
		text-transform: uppercase;
		transition: all ease 0.3s;

		&:hover , &.active {
			color: $wheat;
			opacity: 1;
		}

	}

}
