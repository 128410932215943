.contact-address {
	color: $body-color;

	h2 {
		font-family: $ff2;
		font-weight: 300;
		font-size: 32px;
		line-height: 36px;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		li {
			font-size: 16px;
			line-height: 1.4;
			font-weight: 400;
			padding: 10px 0;

			a {
				color: $white-five;
				text-decoration: underline;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		padding: 40px 0;
	}
}