.product-gallery {

	&__item {
		margin-right: 20px;
		opacity: 0.2;
		transition: all ease 0.3s;

		&.is-selected {
			opacity: 1;
		}
	}

	&__image {
		width: 100%;
	}

	@include media-breakpoint-down(sm) {
		&__item {
			width: 90%;
		}

		&__image {
			width: 100%;
		}
	}
}

