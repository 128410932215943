.contact-form {
	position: relative;
	background: $charcoal-grey-two-2;
	color: $white-five;
	margin-top: -150px;

	&:before {
		content: '';
		width: 440px;
		height: 440px;
		border-radius: (440px / 2);
		position: absolute;
		bottom: 0;
		right: -180px;
		background: $brownish;
		transform: translate(0, 50%);
		z-index: -1;
	}

	&__title {
		font-family: $ff2;
		font-weight: 300;
		font-size: 32px;
		line-height: 36px;
		margin-bottom: 40px;
	}

	@include media-breakpoint-down(md) {
		&:before {
			display: none;
		}
	}

	@include media-breakpoint-down(sm) {
		margin-top: 0;
	}
}
