.box-group {
	margin: 70px 0;

	&__head {
		margin-bottom: 40px;
		@include clearfix();
	}

	&__body {
		@include clearfix();
	}

	&__foot {
		margin-top: 40px;
	}


	&__title {
		font-size: 32px;
		line-height: 1;
		font-family: $ff2;
		font-weight: 300;
	}

	&__description {
		font-size: 14px;
		line-height: 1.6;
		font-family: $ff2;
		margin-top: 20px;
	}

	&__btn {
		min-width: 160px;
		margin-top: 30px;
	}

	&__image {
	}


	&--catalog {

		.box-group__body {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				right: 30px;
				top: 50%;
				border: 20px solid $wheat;
				width: 300px;
				height: 160px;
				z-index: 0;
				transform: translate(0, -50%);
			}

			img {
				position: relative;
				z-index: 1;
				transform: translate(-25%, 10%);
			}
		}

		@include media-breakpoint-down(md) {
			.box-group__body {
				&:after {
					display: none;
				}

				img {
					transform: none;
				}
			}
		}

	}


	@include media-breakpoint-down(sm) {
		margin: 60px 0;
	}

	@include media-breakpoint-down(md) {
		margin: 80px 0;
	}

}
