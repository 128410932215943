.gallery {
	&__item {
		transition: $transition-base;

		&:hover {
			opacity: 0.8;
		}
	}

	&__link {
	}

	&__image {
	}
}